<template>
    <div>
        <card-header title="Trip Photos" :subheader="trip.title" icon="fa-images"/>
        <card-toolbar>
            <button @click="$openCard('new-trip-photo', {tripId: props.tripId}, card);"><i class="fas fa-plus mr-2"/>New Photo</button>
        </card-toolbar>

        <card-body>
            <card-list>
                <subheader-list-item title="Photos" icon="fas fa-images" :badge="photos.length"/>
                <draggable v-model="photos" handle=".drag-handle" @end="completeDrag">
                    <transition-group>
                        <photo-list-item
                            class="t-card-list-item"
                            :photo="p"
                            :card="card"
                            :key="p.id"
                            :active="p.active"
                            v-for="p in photos"
                            @click="$openCard('edit-trip-photo', {photoId: p.id}, card)"
                        />
                    </transition-group>
                </draggable>
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import CardToolbar from "../../TIER/components/CardToolbar";

    import {client as http} from '../../http_client';
    import draggable from "vuedraggable";
    import CardBody from "../../TIER/components/CardBody";
    import TripAddOnListItem from "../../components/TripAddOnListItem";
    import PhotoListItem from "@/components/PhotoListItem";
    import SubheaderListItem from "@/TIER/components/SubheaderListItem";

    export default {
        props: ['card', 'child', 'props'],
        components: {SubheaderListItem, draggable, PhotoListItem, TripAddOnListItem, CardBody, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                photos: [],
                trip: {}
            }
        },
        methods: {
            loadPhotos: function(force) {
                this.$emit('loading', true);
                http.get('/api/trips/' + this.props.tripId + '/photos/0/30', {force}).then(response => {
                    for (let i = 0; i < response.data.photos.length; i++) {
                        response.data.photos[i].active = false;
                    }
                    this.photos = response.data.photos;
                    this.setActive();
                    this.$emit('loading', false);
                });
            },
            loadTrip: function() {
                this.$emit('loading', true);
                http.get('/api/trips/' + this.props.tripId).then(response => {
                    this.trip = response.data;
                });
            },
            setActive: function () {
                let check = (this.child && this.child.definition.component === 'edit-trip-photo') ? parseInt(this.child.props.photoId) : null;
                this.photos.map(p => p.active = (p.id === check));
            },
            completeDrag: function() {
                http.post('/api/trip_photos/order', this.photos.map(p => p.id)).then(response => {

                });
            },
            reload: function() {
                this.loadPhotos(true);
            }
        },
        watch: {
            'props.tripId': function() {
                this.loadPhotos(true);
            },
            'child': function () {
                this.setActive();
            }
        },
        mounted() {
            this.loadPhotos(true);
            this.loadTrip();
        }
    };
</script>

<style lang="scss" scoped>

</style>
